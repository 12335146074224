import React from 'react'
import ReactDOM from 'react-dom'
import 'date-fns'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import lvLocale from 'date-fns/locale/lv'
import { ThemeProvider } from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import 'date-fns'
import App from './App'
import { theme, internalTheme } from './theme'

ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={lvLocale}>
      <Router>
        <Route path='/' exact>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Route>
        <Route path='/internal' exact>
          <ThemeProvider theme={internalTheme}>
            <App />
          </ThemeProvider>
        </Route>
      </Router>
    </MuiPickersUtilsProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
