import { ReactComponent as Alert } from './icons/alert.svg'
import { ReactComponent as Calendar } from './icons/calendar.svg'
import { ReactComponent as Document } from './icons/document.svg'
import { ReactComponent as Hands } from './icons/hands.svg'
import { ReactComponent as Home } from './icons/home.svg'
import { ReactComponent as Letter } from './icons/letter.svg'
import { ReactComponent as Payments } from './icons/payments.svg'
import { ReactComponent as Plan } from './icons/plan.svg'

export const DRAWER_WIDTH = 240
export const DRAWER_WIDTH_MD = 180

export const PAGES = {
  root: 'Sākums',
  paymentRequests: 'Maksājuma pieprasījumi',
  procurementPlan: 'Iepirkumu plāns',
  agreementAndAmendments: 'Līgums un grozījumi',
  submissionSchedule: 'MP iesniegšanas grafiks',
  communication: 'Saziņa',
  documents: 'Dokumenti',
  news: 'Ziņojumu dēlis'
}

export const PAGE_ICONS = {
  root: Home,
  paymentRequests: Payments,
  procurementPlan: Plan,
  agreementAndAmendments: Hands,
  submissionSchedule: Calendar,
  communication: Letter,
  documents: Document,
  news: Alert
}

export const PAGE_TITLES = {
  title: 'Publicitāte',
  subtitle: 'Maksājuma pieprasījumi'
}

export const INTERNAL_PAGES = {
  operationProgram: 'Darbības programma',
  reports: 'Atskaites',
  admin: 'Administrēšana',
  news: 'Ziņojumu dēlis'
}

export const INTERNAL_PAGE_TITLES = {
  title: 'Publicitāte',
  subtitle: 'Projekta Nr.: 7.1.1/0/15/1/001'
}

export const STATUS_NOT_STARTED = 'STATUS_NOT_STARTED'
export const STATUS_IN_PROGRESS = 'STATUS_IN_PROGRESS'
export const STATUS_COMPLETED = 'STATUS_COMPLETED'
export const STATUS_TO_REVIEW = 'STATUS_TO_REVIEW'
export const STATUS_IN_REVIEW = 'STATUS_IN_REVIEW'

export const STATUS_TITLES = {
  STATUS_NOT_STARTED: 'Nav veikts',
  STATUS_IN_PROGRESS: 'Procesā',
  STATUS_COMPLETED: 'Izpildīts',
  STATUS_TO_REVIEW: 'Uz izskatīšanu',
  STATUS_IN_REVIEW: 'Izskatīšanā'
}

export const INTEGER_REGEX = /^[0-9]*[1-9][0-9]*$/

export const VALIDATION = {
  REQUIRED: 'Nav aizpildīts lauks',
  INVALID_PERIOD: 'Nekorekts periods',
  INTEGER_PREFERRED: 'Vēlāms skaitlis'
}

export const DATA_TYPES = [
  {
    name: 'Informatīva plāksne',
    description:
      'Telpās uzstadīts informatīvs plakāts (plakata minimāls izmērs id A3 jeb 297x420mm)',
    required: 4,
    period: 'Visu projekta īstenošanas laiku'
  },
  {
    name: 'Informācija internetā',
    description: 'Internetā vietnē publicēta informācija par...',
    required: 1,
    period: 'Pirmajā gadā'
  },
  {
    name: 'Preses relīzes',
    description: 'Publicēts raksts par...',
    required: 3,
    period: 'Uzsākot projektu'
  },
  { name: 'Jauns' }
]

export const DATE_FORMAT = 'dd.MM.yyyy'
