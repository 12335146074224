import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import SettingsIcon from '@material-ui/icons/Settings'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'

import {
  DRAWER_WIDTH,
  DRAWER_WIDTH_MD,
  PAGES,
  INTERNAL_PAGES,
  PAGE_ICONS
} from './constants'

import { ReactComponent as Logo } from './icons/kpvis.svg'

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH_MD,
      flexShrink: 0
    },
    [theme.breakpoints.up('lg')]: {
      width: DRAWER_WIDTH
    }
  },
  appBar: {
    background: theme.palette.bar.main,
    [theme.breakpoints.up('md')]: {
      width: '100%',
      zIndex: 1300
    }
  },
  circle: {
    position: 'absolute',
    width: 306,
    height: 306,
    opacity: 0.1,
    borderRadius: '50%',
    background: 'transparent',
    border: 'solid 61px white',
    bottom: -50,
    left: 70
  },
  mainToolbar: {
    flexGrow: 1
  },
  secondaryToolbar: {
    backgroundColor: theme.palette.bar.dark
  },
  helpButton: {
    marginLeft: 'auto',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '0.75rem',
    marginRight: theme.spacing(4)
  },
  menuButton: {
    marginLeft: 'auto',
    marginRight: theme.spacing(-1.5),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  user: {
    fontSize: 12,
    fontWeight: 600
  },
  exitButton: {
    marginLeft: theme.spacing(0.5)
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: DRAWER_WIDTH_MD,
    border: 'none',
    boxShadow: '0 2px 14px 0 rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.up('lg')]: {
      width: DRAWER_WIDTH
    }
  },
  list: {
    padding: theme.spacing(3, 1)
  },
  listItem: {
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
      '& .MuiListItemIcon-root': {
        backgroundColor: theme.palette.icons.dark
      }
    },
    '&:not(:last-child)': {
      marginBottom: theme.spacing(0.5)
    }
  },
  listItemActive: {
    backgroundColor: theme.palette.grey[300],
    '& .MuiListItemIcon-root': {
      backgroundColor: theme.palette.icons.dark
    }
  },
  listItemIcon: {
    minWidth: 0,
    backgroundColor: theme.palette.icons.main,
    borderRadius: theme.spacing(0.5),
    width: theme.spacing(2.5),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: theme.transitions.create('background-color')
  },
  listItemText: {
    color: theme.palette.grey[600],
    fontWeight: 600,
    fontSize: '0.8125rem'
  },
  listItemTextActive: {
    color: theme.palette.grey[800]
  }
}))

function Nav (props) {
  const { window } = props
  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const match = useRouteMatch('/internal')

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const pages = match ? INTERNAL_PAGES : PAGES
  const pageIcons = match ? null : PAGE_ICONS

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List className={classes.list}>
        {Object.keys(pages).map((key, index) => {
          const active = match ? index === 0 : index === 1

          return (
            <ListItem
              button
              key={pages[key]}
              className={clsx(classes.listItem, {
                [classes.listItemActive]: active
              })}
            >
              {pageIcons && (
                <ListItemIcon className={classes.listItemIcon}>
                  {React.createElement(pageIcons[key])}
                </ListItemIcon>
              )}
              <ListItemText
                primary={pages[key]}
                primaryTypographyProps={{
                  className: clsx(classes.listItemText, {
                    [classes.listItemTextActive]: active
                  })
                }}
              />
            </ListItem>
          )
        })}
      </List>
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <>
      <CssBaseline />
      <AppBar position='fixed' className={classes.appBar} elevation={0}>
        <div className={classes.circle} />
        <Toolbar disableGutters>
          <Toolbar className={classes.mainToolbar}>
            <Logo />
            <Hidden smDown implementation='js'>
              <Button
                color='inherit'
                className={classes.helpButton}
                startIcon={<HelpOutlineOutlinedIcon />}
              >
                Palīdzība
              </Button>
              <IconButton color='inherit' aria-label='settings' edge='start'>
                <SettingsIcon fontSize='small' />
              </IconButton>
            </Hidden>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon fontSize='small' />
            </IconButton>
          </Toolbar>
          <Hidden smDown implementation='css'>
            <Toolbar className={classes.secondaryToolbar}>
              <Typography
                variant='subtitle2'
                component='strong'
                className={classes.user}
              >
                Kristaps Ozoliņš
              </Typography>
              <IconButton
                color='inherit'
                aria-label='settings'
                edge='start'
                className={classes.exitButton}
              >
                <ExitToAppIcon fontSize='small' />
              </IconButton>
            </Toolbar>
          </Hidden>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label='mailbox folders'>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation='css'>
          <Drawer
            container={container}
            variant='temporary'
            anchor='left'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation='css'>
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant='permanent'
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </>
  )
}

export default Nav
