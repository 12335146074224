import { INTEGER_REGEX, STATUS_TO_REVIEW } from './constants'

const isLegacy = count => !INTEGER_REGEX.test(count)

export const getSum = items =>
  items.reduce((acc, { count }) => (isLegacy(count) ? acc : acc + count), 0)

// export const getDiff = ({ required, items }) => {
//   if (hasLegacy(items)) return '???'
//   else return required - getSum(items)
// }

export const toReviewExist = items =>
  items.find(({ status }) => status === STATUS_TO_REVIEW)
