import React, {
  useState,
  useCallback,
  useContext,
  useMemo,
  useEffect
} from 'react'
import Linkify from 'react-linkify'
import clsx from 'clsx'
import { format } from 'date-fns'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import CreateIcon from '@material-ui/icons/Create'
import CancelIcon from '@material-ui/icons/Cancel'
import RemoveIcon from '@material-ui/icons/RemoveCircle'
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import update from 'immutability-helper'

import DateField from './DateField'
import Confirmation from './Confirmation'

import Context from './context'

import {
  INTEGER_REGEX,
  VALIDATION,
  STATUS_TO_REVIEW,
  DATE_FORMAT
} from './constants'

const Schema = Yup.object().shape({
  description: Yup.string().required(VALIDATION.REQUIRED),
  dateFrom: Yup.date()
    .required(VALIDATION.REQUIRED)
    .nullable(),
  dateTo: Yup.date()
    .nullable()
    .when('dateFrom', (from, schema) =>
      from ? schema.min(from, VALIDATION.INVALID_PERIOD) : schema
    ),
  count: Yup.string().required(VALIDATION.REQUIRED)
})

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    '& .MuiFilledInput-root': {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderRadius: theme.spacing(1),
      transition: theme.transitions.create([
        'border-color',
        'background-color'
      ]),
      backgroundColor: theme.palette.grey[300],
      '&:hover, &.Mui-focused': {
        borderColor: theme.palette.primary.light
      },
      '&.Mui-error': {
        backgroundColor: theme.palette.error.xLight,
        borderColor: theme.palette.error.light
      }
    },
    '& .MuiFilledInput-input': {
      padding: theme.spacing(1.25, 1.25, 1.375),
      fontSize: '0.8125rem'
    },
    '& .MuiInputBase-inputMultiline': {
      [theme.breakpoints.down('sm')]: {
        minHeight: theme.spacing(7)
      }
    },
    '& .MuiFilledInput-multiline': {
      padding: theme.spacing(0, 1.5, 0, 0)
    },
    '& .MuiFilledInput-underline': {
      '&:before, &:after': {
        content: 'none'
      }
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      marginRight: 0,
      whiteSpace: 'nowrap',
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        top: '100%'
      }
    },
    '& .MuiSvgIcon-root': {
      width: '0.75rem',
      height: '0.75rem',
      fill: theme.palette.grey[800]
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.75rem',
      position: 'relative',
      transform: 'none',
      color: theme.palette.grey[600],
      fontWeight: 600,
      marginBottom: theme.spacing(0.5),
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    '& .MuiFormLabel-asterisk': {
      color: theme.palette.warning.main
    }
  },
  formRow: {
    '& td': {
      verticalAlign: 'top'
    }
  },
  date: {
    display: 'inline-block',
    marginTop: theme.spacing(1.5)
  },
  fileLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: theme.spacing(5),
    cursor: 'pointer',
    '&:hover, &:focus': {
      outline: 'none',
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.dark
      }
    },
    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex'
    }
  },
  fileInput: {
    display: 'none'
  },
  files: {
    display: 'flex',
    flexWrap: 'wrap',
    color: theme.palette.primary.main,
    fontSize: '0.75rem',
    fontWeight: 600,
    '& div': {
      display: 'inline-flex',
      alignItems: 'center',
      marginRight: theme.spacing(1),
      cursor: 'pointer',
      '& svg.remove': {
        color: theme.palette.grey[800],
        fontSize: '1rem',
        marginLeft: theme.spacing(0.5)
      }
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:visited': {
        color: theme.palette.primary.main
      }
    }
  },
  filesMargin: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3)
    }
  },
  widthAuto: {
    [theme.breakpoints.down('sm')]: {
      width: 'auto !important'
    }
  },
  readMore: {
    display: 'block'
  }
}))

export const StyledTableCell = withStyles(theme => ({
  root: {
    color: theme.palette.grey[600],
    fontWeight: 600,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderColor: theme.palette.grey[400],
    '&:first-child': {
      paddingLeft: theme.spacing(4.5),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2)
      }
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2)
    }
  }
}))(TableCell)

const StyledTableBodyRow = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      '&:first-child': {
        '& td': {
          paddingTop: theme.spacing(1.25)
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      width: `calc(100vw - ${theme.spacing(3)}px)`,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.grey[400],
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  }
}))(TableRow)

const StyledTableBodyCell = withStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontSize: '0.8125rem',
    '&:not(:first-child)': {
      color: theme.palette.grey[800],
      fontWeight: 400
    },
    [theme.breakpoints.down('sm')]: {
      border: 'none',
      width: '100%',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    '& p': {
      margin: 0
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      fontWeight: 600,
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }
}))(StyledTableCell)

const StyledButton = withStyles(theme => ({
  root: {
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '0.8125rem',
    width: '100%'
  }
}))(Button)

const MetaLabel = withStyles(theme => ({
  root: {
    color: theme.palette.grey[600],
    fontWeight: 600,
    fontSize: '0.75rem',
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}))(Typography)

const Files = ({ files, className, onRemove }) =>
  files.length ? (
    <div className={className}>
      {files.map((item, index) => (
        <div key={item}>
          <InsertDriveFileIcon fontSize='small' />
          <a
            target='__blank'
            rel='noreferrer'
            href='https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg/1200px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg'
          >
            {item}
          </a>
          {onRemove && (
            <CancelIcon
              fontSize='small'
              className='remove'
              onClick={() => onRemove(index)}
            />
          )}
        </div>
      ))}
    </div>
  ) : null

const Dates = ({ dateFrom, dateTo }) => (
  <React.Fragment>
    {format(dateFrom, DATE_FORMAT)}
    {dateTo && (
      <React.Fragment>
        {' - '}
        {format(dateTo, DATE_FORMAT)}
      </React.Fragment>
    )}
  </React.Fragment>
)

export default ({ id, item, index }) => {
  const classes = useStyles()
  const { data, setData } = useContext(Context)
  const [edit, setEdit] = useState(!!!item)
  const [confirmation, setConfirmation] = useState(false)
  const needTruncate = useMemo(() => item && item.description.length > 250, [
    item
  ])
  const [truncated, setTruncated] = useState(needTruncate)

  useEffect(() => {
    setTruncated(needTruncate)
  }, [needTruncate])

  const parentIndex = useMemo(
    () => data.findIndex(({ id: itemId }) => itemId === id),
    [data, id]
  )

  const processValues = useCallback(
    ({ count, ...others }) => ({
      count: INTEGER_REGEX.test(count) ? parseInt(count) : count,
      ...others
    }),
    []
  )

  const onSubmit = useCallback(
    (values, { resetForm }) => {
      if (!!!item) {
        setData(
          update(data, {
            [parentIndex]: {
              status: { $set: STATUS_TO_REVIEW },
              items: {
                $unshift: [{ date: new Date(), ...processValues(values) }]
              }
            }
          })
        )
      } else {
        setData(
          update(data, {
            [parentIndex]: {
              status: { $set: STATUS_TO_REVIEW },
              items: {
                [index]: {
                  $set: {
                    ...data[parentIndex].items[index],
                    ...processValues(values)
                  }
                }
              }
            }
          })
        )

        setEdit(false)
      }

      resetForm()
    },
    [data, setData, item, processValues, index, parentIndex]
  )

  const onRemove = useCallback(() => {
    setData(
      update(data, {
        [parentIndex]: {
          status: { $set: STATUS_TO_REVIEW },
          items: { $splice: [[index, 1]] }
        }
      })
    )

    setConfirmation(false)
  }, [parentIndex, setData, index, data])

  return (
    <React.Fragment>
      <Formik
        initialValues={
          item || {
            description: '',
            dateFrom: null,
            dateTo: null,
            count: '',
            files: []
          }
        }
        onSubmit={onSubmit}
        validationSchema={Schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, setFieldValue, submitForm }) =>
          edit ? (
            <StyledTableBodyRow className={classes.formRow}>
              <Hidden smDown implementation='js'>
                <StyledTableBodyCell>
                  <span className={classes.date}>
                    {format(item ? item.date : new Date(), DATE_FORMAT)}
                  </span>
                </StyledTableBodyCell>
              </Hidden>
              <StyledTableBodyCell>
                <Field name='description'>
                  {({ field, meta: { error } }) => (
                    <TextField
                      variant='filled'
                      className={classes.input}
                      multiline
                      error={!!error}
                      helperText={error}
                      label='Apraksts'
                      required
                      InputProps={
                        field.value
                          ? {
                              endAdornment: (
                                <InputAdornment
                                  position='end'
                                  onClick={() =>
                                    setFieldValue('description', '')
                                  }
                                >
                                  <IconButton
                                    aria-label='Notīrīt'
                                    color='default'
                                    size='small'
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }
                          : null
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      {...field}
                    />
                  )}
                </Field>
                <Field name='files'>
                  {({ field: { value } }) => (
                    <Files
                      className={clsx(classes.files, classes.filesMargin)}
                      files={value}
                      onRemove={index => {
                        setFieldValue(
                          'files',
                          update(value, {
                            $splice: [[index, 1]]
                          })
                        )
                      }}
                    />
                  )}
                </Field>
              </StyledTableBodyCell>
              <StyledTableBodyCell>
                <DateField
                  name='dateFrom'
                  label='Īstenošanas periods'
                  className={clsx(classes.input, classes.dateInput)}
                />
              </StyledTableBodyCell>
              <StyledTableBodyCell>
                <DateField
                  name='dateTo'
                  className={clsx(classes.input, classes.dateInput)}
                />
              </StyledTableBodyCell>
              <StyledTableBodyCell>
                <Field name='count'>
                  {({ field, meta: { error } }) => {
                    const isInteger = INTEGER_REGEX.test(field.value)

                    return (
                      <TextField
                        variant='filled'
                        error={!!error}
                        className={classes.input}
                        helperText={
                          error ||
                          (!!field.value && !isInteger
                            ? VALIDATION.INTEGER_PREFERRED
                            : null)
                        }
                        label='Skaits'
                        required
                        InputProps={
                          field.value
                            ? {
                                endAdornment: (
                                  <InputAdornment
                                    position='end'
                                    onClick={() => setFieldValue('count', '')}
                                  >
                                    <IconButton
                                      aria-label='Notīrīt'
                                      color='default'
                                      size='small'
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }
                            : null
                        }
                        InputLabelProps={{
                          shrink: true
                        }}
                        {...field}
                      />
                    )
                  }}
                </Field>
              </StyledTableBodyCell>
              <StyledTableBodyCell>
                <Field name='files'>
                  {({ field: { value } }) => {
                    return (
                      <React.Fragment>
                        <label
                          htmlFor={`file-${index}`}
                          className={classes.fileLabel}
                          tabIndex='0'
                        >
                          <NoteAddOutlinedIcon
                            color='primary'
                            fontSize='large'
                          />
                        </label>
                        <input
                          type='file'
                          multiple
                          className={classes.fileInput}
                          id={`file-${index}`}
                          onChange={({ target: { files } }) =>
                            setFieldValue(
                              'files',
                              update(value, {
                                $push: Array.from(files).map(({ name }) => name)
                              })
                            )
                          }
                        />
                      </React.Fragment>
                    )
                  }}
                </Field>
              </StyledTableBodyCell>
              {!!!item ? (
                <StyledTableBodyCell colSpan={2}>
                  <StyledButton
                    variant='contained'
                    color='primary'
                    disableElevation
                    size='large'
                    onClick={submitForm}
                  >
                    Pievienot
                  </StyledButton>
                </StyledTableBodyCell>
              ) : (
                <React.Fragment>
                  <StyledTableBodyCell>
                    <StyledButton
                      variant='contained'
                      color='primary'
                      disableElevation
                      size='large'
                      onClick={submitForm}
                    >
                      Saglabāt
                    </StyledButton>
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    <StyledButton
                      variant='contained'
                      color='default'
                      disableElevation
                      size='large'
                      onClick={() => setEdit(false)}
                    >
                      Atcelt
                    </StyledButton>
                  </StyledTableBodyCell>
                </React.Fragment>
              )}
            </StyledTableBodyRow>
          ) : (
            <StyledTableBodyRow>
              <StyledTableBodyCell>
                {format(item.date, DATE_FORMAT)}
              </StyledTableBodyCell>
              <StyledTableBodyCell>
                <p>
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a
                        target='__blank'
                        href={decoratedHref}
                        rel='noreferrer'
                        key={key}
                      >
                        {decoratedText}
                      </a>
                    )}
                  >
                    {truncated
                      ? item.description.slice(0, 250)
                      : item.description}
                  </Linkify>
                  {truncated && '... '}
                </p>
                {needTruncate && (
                  <a
                    href='#'
                    onClick={() => setTruncated(t => !t)}
                    className={classes.readMore}
                  >
                    {truncated ? ' Rādīt vairāk ...' : ' Rādīt mazāk'}
                  </a>
                )}
                <Files className={classes.files} files={item.files} />
              </StyledTableBodyCell>
              <StyledTableBodyCell colSpan={2}>
                <MetaLabel variant='subtitle2' component='strong'>
                  Īstenošanas periods
                </MetaLabel>
                <Dates dateTo={item.dateTo} dateFrom={item.dateFrom} />
              </StyledTableBodyCell>
              <StyledTableBodyCell colSpan={2}>
                <MetaLabel variant='subtitle2' component='strong'>
                  Skaits
                </MetaLabel>
                {item.count}
              </StyledTableBodyCell>
              <StyledTableBodyCell className={classes.widthAuto}>
                <IconButton
                  aria-label='Rediģēt'
                  color='primary'
                  size='small'
                  onClick={() => setEdit(true)}
                >
                  <CreateIcon />
                </IconButton>
              </StyledTableBodyCell>
              <StyledTableBodyCell className={classes.widthAuto}>
                <IconButton
                  aria-label='Izdzēst'
                  color='primary'
                  size='small'
                  onClick={() => setConfirmation(true)}
                >
                  <RemoveIcon />
                </IconButton>
              </StyledTableBodyCell>
            </StyledTableBodyRow>
          )
        }
      </Formik>
      <Confirmation
        open={confirmation}
        onRemove={onRemove}
        onClose={() => setConfirmation(false)}
      />
    </React.Fragment>
  )
}
