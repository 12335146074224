import React from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Check from '@material-ui/icons/Check'
import Close from '@material-ui/icons/Close'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import Typography from '@material-ui/core/Typography'

import {
  STATUS_NOT_STARTED,
  STATUS_IN_PROGRESS,
  STATUS_COMPLETED,
  STATUS_TO_REVIEW,
  STATUS_IN_REVIEW,
  STATUS_TITLES
} from './constants'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  typography: {
    fontWeight: 600
  },
  iconWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      marginRight: theme.spacing(0.5)
    }
  },
  notStarted: {
    color: theme.palette.grey[600]
  },
  inProgress: {
    color: theme.palette.warning.main
  },
  inProgressIcon: {
    fontSize: 10
  },
  completed: {
    color: theme.palette.success.main
  },
  review: {
    color: theme.palette.primary.main
  }
}))

export default ({ status }) => {
  const theme = useTheme()
  const classes = useStyles()

  const isNotStarted = status === STATUS_NOT_STARTED
  const isInProgress = status === STATUS_IN_PROGRESS
  const isCompleted = status === STATUS_COMPLETED
  const isReview = [STATUS_TO_REVIEW, STATUS_IN_REVIEW].includes(status)

  return (
    <div className={classes.root}>
      <div className={classes.iconWrapper}>
        {isNotStarted ? (
          <Close fontSize='small' style={{ color: theme.palette.grey[600] }} />
        ) : isInProgress ? (
          <FiberManualRecordIcon
            className={clsx(classes.inProgressIcon)}
            style={{ color: theme.palette.warning.main }}
          />
        ) : isCompleted ? (
          <Check
            fontSize='small'
            style={{ color: theme.palette.success.main }}
          />
        ) : isReview ? (
          <FiberManualRecordIcon
            className={clsx(classes.inProgressIcon)}
            style={{ color: theme.palette.primary.main }}
          />
        ) : null}
      </div>
      <Typography
        variant='body2'
        className={clsx(classes.typography, {
          [classes.notStarted]: isNotStarted,
          [classes.inProgress]: isInProgress,
          [classes.completed]: isCompleted,
          [classes.review]: isReview
        })}
      >
        {STATUS_TITLES[status]}
      </Typography>
    </div>
  )
}
