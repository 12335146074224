import {
  STATUS_NOT_STARTED,
  STATUS_IN_PROGRESS,
  STATUS_COMPLETED,
  STATUS_IN_REVIEW
} from './constants'

export default [
  {
    id: 1,
    name: 'Informatīva plāksne',
    description:
      'Telpās uzstadīts informatīvs plakāts (plakata minimāls izmērs id A3 jeb 297x420mm)',
    period: 'Visu projekta īstenošanas laiku',
    required: 4,
    status: STATUS_IN_PROGRESS,
    removable: false,
    items: [
      {
        date: new Date('2020-08-12T07:52:12.110Z'),
        description: 'Tika nodrošināts plakāts no 15.07.2019',
        dateFrom: new Date('2020-08-06T10:52:12.110Z'),
        dateTo: new Date('2020-08-22T10:52:12.110Z'),
        count: 1,
        files: ['img_poster_123.jpg', 'img_draft.jpg']
      },
      {
        date: new Date('2020-08-10T12:06:12.110Z'),
        description:
          'Tika nodrošināts plakāts no 15.04.2019., bet tipogrāfiski iespiests A3 plakāts 20.06.2016., uz kura ir izvietota informācija par lorem ipsum dolor sit amet.',
        dateFrom: new Date('2020-05-11T17:52:12.110Z'),
        dateTo: new Date('2020-06-01T17:52:12.110Z'),
        count: 2,
        files: []
      }
    ]
  },
  {
    id: 2,
    name: 'Preses relīzes',
    description: 'Publicēts raksts par...',
    period: 'Uzsākot projektu',
    required: 3,
    status: STATUS_IN_REVIEW,
    removable: false,
    items: []
  },
  {
    id: 3,
    name: 'Informācija internetā',
    description: 'Internetā vietnē publicēta informācija par...',
    period: 'Pirmajā gadā',
    required: 1,
    status: STATUS_IN_PROGRESS,
    removable: false,
    items: [
      {
        date: new Date('2020-05-10T07:52:12.110Z'),
        description: 'Tika publicēts raksts 24.07.2019',
        dateFrom: new Date('2020-05-11T04:52:12.110Z'),
        dateTo: new Date('2020-05-16T04:52:12.110Z'),
        count: '10 lapas',
        files: []
      }
    ]
  },
  {
    id: 4,
    name: 'Preses relīzes',
    description: 'Publicēts raksts par...',
    period: 'Uzsākot projektu',
    required: 3,
    status: STATUS_NOT_STARTED,
    removable: false,
    items: []
  },
  {
    id: 5,
    name: 'Informatīva plāksne',
    description:
      'Telpās uzstadīts informatīvs plakāts (plakata minimāls izmērs id A3 jeb 297x420mm)',
    required: 1,
    period: 'Pirmajā gadā',
    status: STATUS_COMPLETED,
    removable: false,
    items: [
      {
        date: new Date('2020-09-10T07:52:12.110Z'),
        description: 'Tika nodrošināts plakāts no 15.07.2019',
        dateFrom: new Date('2020-03-29T12:52:12.110Z'),
        dateTo: new Date('2020-04-21T12:52:12.110Z'),
        count: 1,
        files: []
      }
    ]
  }
]
