import React, { useContext, useState, useCallback } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import CancelIcon from '@material-ui/icons/Cancel'

import update from 'immutability-helper'

import StatusLabel from './StatusLabel'
import Confirmation from './Confirmation'
import Context from './context'

const useStyles = makeStyles(theme => ({
  table: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      minWidth: 650
    }
  },
  tableHead: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}))

const StyledTableRow = withStyles(theme => ({
  root: {
    boxShadow: '0 2px 14px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
    transition: theme.transitions.create('box-shadow'),
    '&:hover, &:focus': {
      boxShadow: '0 2px 68px 0 rgba(0, 0, 0, 0.15)',
      '& td': {
        backgroundColor: '#fff'
      }
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3, 7),
      position: 'relative'
    }
  }
}))(TableRow)

const StyledTableCell = withStyles(theme => ({
  root: {
    border: 'none',
    padding: theme.spacing(3, 4),
    color: theme.palette.grey[600],
    fontWeight: 600,
    '&:first-child': {
      borderRadius: theme.spacing(1, 0, 0, 1)
    },
    '&:last-child': {
      borderRadius: theme.spacing(0, 1, 1, 0)
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5, 0),
      '&:first-child': {
        position: 'absolute',
        top: theme.spacing(3),
        left: theme.spacing(2.5)
      }
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: '#fbfbfb'
    }
  }
}))(TableCell)

const StyledTableHeadCell = withStyles(() => ({
  root: {
    background: 'transparent',
    fontSize: '0.75rem',
    '&:first-child, &:nth-child(2)': {
      textTransform: 'uppercase'
    }
  }
}))(StyledTableCell)

const StyledTableBodyCell = withStyles(theme => ({
  root: {
    color: theme.palette.grey[800]
  }
}))(StyledTableCell)

const EmptyTableRow = withStyles(() => ({
  root: {
    visibility: 'hidden'
  }
}))(TableRow)

const EmptyTableCell = withStyles(theme => ({
  root: {
    border: 'none',
    padding: theme.spacing(0.5)
  }
}))(TableCell)

export default function SimpleTable ({ openItem }) {
  const classes = useStyles()
  const { data, setData } = useContext(Context)
  const [confirmation, setConfirmation] = useState(null)

  const onRemove = useCallback(
    id => {
      const index = data.findIndex(({ id: itemId }) => itemId === id)

      setData(update(data, { $splice: [[index, 1]] }))

      setConfirmation(null)
    },
    [data, setData]
  )

  return (
    <React.Fragment>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <StyledTableHeadCell>ID</StyledTableHeadCell>
            <StyledTableHeadCell>Veids</StyledTableHeadCell>
            <StyledTableHeadCell>Īstenošanas periods</StyledTableHeadCell>
            <StyledTableHeadCell>Skaits</StyledTableHeadCell>
            <StyledTableHeadCell>Statuss</StyledTableHeadCell>
            <StyledTableHeadCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(item => (
            <React.Fragment key={item.id}>
              <StyledTableRow tabIndex={0} onClick={() => openItem(item.id)}>
                <StyledTableBodyCell>
                  {String(item.id).padStart(2, '0')}
                </StyledTableBodyCell>
                <StyledTableBodyCell scope='row'>
                  {item.name}
                </StyledTableBodyCell>
                <StyledTableBodyCell>{item.period}</StyledTableBodyCell>
                <StyledTableBodyCell>{item.required}</StyledTableBodyCell>
                <StyledTableBodyCell colSpan={item.removable ? 1 : 2}>
                  <StatusLabel status={item.status} />
                </StyledTableBodyCell>
                {item.removable && (
                  <StyledTableBodyCell>
                    <IconButton
                      aria-label='Izdzēst'
                      color='primary'
                      size='small'
                      onClick={e => {
                        e.stopPropagation()
                        setConfirmation(item.id)
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </StyledTableBodyCell>
                )}
              </StyledTableRow>
              <EmptyTableRow aria-hidden>
                <EmptyTableCell colSpan={5} />
              </EmptyTableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <Confirmation
        open={!!confirmation}
        onRemove={() => onRemove(confirmation)}
        onClose={() => setConfirmation(null)}
      />
    </React.Fragment>
  )
}
