import React from 'react'
import clsx from 'clsx'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const useStyles = makeStyles(theme => ({
  confirmButton: {
    textTransform: 'none',
    fontWeight: 600
  },
  dangerButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.error.dark
    }
  }
}))

const StyledDialogTitle = withStyles(theme => ({
  root: {
    color: theme.palette.grey[800],
    paddingBottom: 0,
    '& .MuiTypography-h6': {
      fontSize: '1.5rem'
    }
  }
}))(DialogTitle)

const StyledDialogContentText = withStyles(theme => ({
  root: {
    color: theme.palette.grey[600],
    fontWeight: 600,
    paddingBottom: 0
  }
}))(DialogContentText)

export default ({ open, onRemove, onClose }) => {
  const classes = useStyles()

  return (
    <Dialog open={open} disableBackdropClick disableEscapeKeyDown maxWidth='xs'>
      <StyledDialogTitle>Dzēst</StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText>
          Vai tiešām dzēst ierakstu?
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onRemove}
          color='primary'
          variant='contained'
          className={clsx(classes.confirmButton, classes.dangerButton)}
        >
          Dzēst
        </Button>
        <Button
          onClick={onClose}
          color='inherit'
          className={classes.confirmButton}
        >
          Atcelt
        </Button>
      </DialogActions>
    </Dialog>
  )
}
