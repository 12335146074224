import React, { useCallback, useContext, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import CloseIcon from '@material-ui/icons/Close'

import update from 'immutability-helper'

import StatusLabel from './StatusLabel'
import ModalTable from './ModalTable'

import Context from './context'
import {
  STATUS_IN_PROGRESS,
  STATUS_COMPLETED,
  STATUS_IN_REVIEW
} from './constants'

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction='up' ref={ref} {...props} />
})

const StyledDialog = withStyles(theme => ({
  paper: {
    maxWidth: 'none',
    // width: `calc(100vw - ${theme.spacing(2)}px)`,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 0, 0),
      alignSelf: 'flex-end',
      maxWidth: '100vw !important'
    }
  }
}))(Dialog)

const StyledDialogContent = withStyles(theme => ({
  root: {
    position: 'relative',
    padding: theme.spacing(5, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 1),
      // override Material UI styles
      '&:first-child': {
        paddingTop: theme.spacing(6)
      }
    },
    // override Material UI styles
    '&:first-child': {
      paddingTop: theme.spacing(5)
    }
  }
}))(DialogContent)

const CloseButton = withStyles(theme => ({
  root: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: theme.palette.grey[600],
    fontWeight: 700,
    textTransform: 'none'
  }
}))(Button)

const useStyles = makeStyles(theme => ({
  description: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 4.5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2)
    }
  },
  meta: {
    display: 'flex',
    marginTop: theme.spacing(3),
    padding: theme.spacing(0, 4.5),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: theme.spacing(0, 2)
    }
  },
  metaItem: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(2)
      }
    },
    [theme.breakpoints.up('md')]: {
      '&:not(:last-child)': {
        marginRight: theme.spacing(10)
      }
    }
  },
  line: {
    margin: theme.spacing(3, 0, 0),
    height: 1,
    backgroundColor: theme.palette.grey[400]
  },
  buttons: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1, 0, 2)
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4.5)
    }
  },
  button: {
    textTransform: 'none',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  rejectButton: {
    color: 'white',
    backgroundColor: theme.palette.grey[500],
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[600]
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.5)
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1.5)
    }
  }
}))

const Title = withStyles(theme => ({
  root: {
    color: theme.palette.grey[800],
    fontWeight: 600,
    fontSize: '1rem'
  }
}))(Typography)

const Description = withStyles(theme => ({
  root: {
    color: theme.palette.grey[600],
    fontWeight: 600,
    fontSize: '0.8125rem'
  }
}))(Typography)

const Subtitle = withStyles(() => ({
  root: {
    fontSize: '1.25rem'
  }
}))(Description)

const MetaTitle = withStyles(() => ({
  root: {
    fontSize: '0.75rem'
  }
}))(Description)

const MetaText = withStyles(theme => ({
  root: {
    color: theme.palette.grey[800],
    fontWeight: 600,
    fontSize: '0.8125rem'
  }
}))(Typography)

export default ({ open, onClose, item }) => {
  const classes = useStyles()
  const match = useRouteMatch('/internal')
  const { data, setData } = useContext(Context)

  const index = useMemo(
    () => (item ? data.findIndex(({ id }) => item.id === id) : null),
    [item, data]
  )

  const approve = useCallback(
    () =>
      setData(
        update(data, { [index]: { status: { $set: STATUS_COMPLETED } } })
      ),
    [data, index, setData]
  )

  const reject = useCallback(
    () =>
      setData(
        update(data, { [index]: { status: { $set: STATUS_IN_PROGRESS } } })
      ),
    [data, index, setData]
  )

  return (
    <StyledDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      scroll='body'
    >
      {item && (
        <StyledDialogContent>
          <CloseButton
            color='default'
            size='small'
            endIcon={<CloseIcon />}
            onClick={onClose}
          >
            Aizvērt
          </CloseButton>
          <div className={classes.description}>
            <Subtitle variant='h6' component='h4'>
              {String(item.id).padStart(2, '0')}
            </Subtitle>
            <Title variant='h5' component='h2'>
              {item.name}
            </Title>
            <Description variant='subtitle2'>{item.description}</Description>
          </div>
          <div className={classes.meta}>
            <div className={classes.metaItem}>
              <MetaTitle variant='subtitle1' component='span'>
                Īstenošanas periods
              </MetaTitle>
              <MetaText variant='subtitle2' component='strong'>
                {item.period}
              </MetaText>
            </div>
            <div className={classes.metaItem}>
              <MetaTitle variant='subtitle1' component='span'>
                Skaits vajadzīgs
              </MetaTitle>
              <MetaText variant='subtitle2' component='strong'>
                {item.required}
              </MetaText>
            </div>
            <div className={classes.metaItem}>
              <MetaTitle variant='subtitle1' component='span'>
                Statuss
              </MetaTitle>
              <MetaText variant='subtitle2' component='strong'>
                <StatusLabel status={item.status} />
              </MetaText>
            </div>
          </div>
          <div className={classes.line} />
          <form>
            <ModalTable item={item} />
          </form>
          {match && item.status === STATUS_IN_REVIEW && (
            <div className={classes.buttons}>
              <Button
                onClick={approve}
                color='primary'
                variant='contained'
                size='large'
                className={classes.button}
              >
                Apstiprināt
              </Button>
              <Button
                onClick={reject}
                color='inherit'
                variant='contained'
                size='large'
                className={clsx(classes.button, classes.rejectButton)}
              >
                Noraidīt
              </Button>
            </div>
          )}
        </StyledDialogContent>
      )}
    </StyledDialog>
  )
}
