import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'

import ModalTableRow, { StyledTableCell } from './ModalTableRow'

const useStyles = makeStyles(theme => ({
  table: {
    // tableLayout: 'fixed',
    '& th': {
      '&:nth-child(1)': {
        width: '10%',
        minWidth: 115
      },
      '&:nth-child(2)': {
        width: '50%'
      },
      '&:nth-child(3)': {
        width: '15%',
        minWidth: 290
      },
      '&:nth-child(4)': {
        width: '10%',
        minWidth: 90
      }
    },
    '& thead': {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  },
  asterisk: {
    color: theme.palette.warning.main
  },
  icon: {
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1)
  },
  listItem: {}
}))

const StyledTableHeadCell = withStyles(theme => ({
  root: {
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
    border: 'none',
    paddingBottom: 0
  }
}))(StyledTableCell)

// const StyledTableFooter = withStyles(theme => ({
//   root: {
//     [theme.breakpoints.down('sm')]: {
//       display: 'flex',
//       flexDirection: 'column'
//     }
//   }
// }))(TableFooter)

// const StyledTableFooterRow = withStyles(theme => ({
//   root: {
//     '&:first-child td': {
//       paddingTop: theme.spacing(2)
//     },
//     [theme.breakpoints.down('sm')]: {
//       display: 'flex',
//       justifyContent: 'flex-end'
//     }
//   }
// }))(TableRow)

// const StyledTableFooterCell = withStyles(theme => ({
//   root: {
//     border: 'none',
//     fontSize: '0.8125rem',
//     paddingTop: theme.spacing(0.25),
//     paddingBottom: theme.spacing(0.25),
//     '&:first-child': {
//       textAlign: 'right',
//       fontSize: '0.75rem'
//     }
//   }
// }))(StyledTableCell)

// const StyledTableFooterCountCell = withStyles(theme => ({
//   root: {
//     fontSize: '0.75rem',
//     fontWeight: 700,
//     color: theme.palette.grey[800]
//   }
// }))(StyledTableFooterCell)

export default ({ item }) => {
  const classes = useStyles()

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <StyledTableHeadCell>Datums</StyledTableHeadCell>
          <StyledTableHeadCell>
            Apraksts
            <span className={classes.asterisk}> *</span>
          </StyledTableHeadCell>
          <StyledTableHeadCell colSpan={2}>
            Īstenošanas periods
            <span className={classes.asterisk}> *</span>
            <Tooltip title='Delete'>
              <ErrorOutlineOutlinedIcon
                color='primary'
                fontSize='small'
                className={classes.icon}
              />
            </Tooltip>
          </StyledTableHeadCell>
          <StyledTableHeadCell>
            Skaits
            <span className={classes.asterisk}> *</span>
            <Tooltip title='Delete'>
              <ErrorOutlineOutlinedIcon
                color='primary'
                fontSize='small'
                className={classes.icon}
              />
            </Tooltip>
          </StyledTableHeadCell>
          <StyledTableHeadCell />
          <StyledTableHeadCell />
        </TableRow>
      </TableHead>
      <TableBody>
        <ModalTableRow id={item.id} />
        {item.items.map((child, index) => (
          <ModalTableRow key={index} item={child} id={item.id} index={index} />
        ))}
      </TableBody>
      {/* <StyledTableFooter>
        <StyledTableFooterRow>
          <StyledTableFooterCell colSpan={4}>
            Kopā skaits:
          </StyledTableFooterCell>
          <StyledTableFooterCountCell>
            {getSum(item.items)}
          </StyledTableFooterCountCell>
        </StyledTableFooterRow>
        <StyledTableFooterRow>
          <StyledTableFooterCell colSpan={4}>
            Nepieciešams:
          </StyledTableFooterCell>
          <StyledTableFooterCell>{item.required}</StyledTableFooterCell>
        </StyledTableFooterRow>
        <StyledTableFooterRow>
          <StyledTableFooterCell colSpan={4}>Trūkst:</StyledTableFooterCell>
          <StyledTableFooterCell>{getDiff(item)}</StyledTableFooterCell>
        </StyledTableFooterRow>
      </StyledTableFooter> */}
    </Table>
  )
}
