import { createMuiTheme } from '@material-ui/core/styles'

const common = {
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 13
  },
  palette: {
    grey: {
      300: '#f5f6fa',
      400: '#e4e6ed',
      500: '#99a6b0',
      600: '#6a7581',
      800: '#3c404a'
    },
    primary: {
      light: '#94a6f2',
      main: '#4461da',
      dark: '#213586'
    },
    success: {
      main: '#2c9d4d'
    },
    warning: {
      main: '#d28a14'
    },
    error: {
      main: '#f44b5c',
      light: '#ffe2e2',
      xLight: '#fff2f2'
    },
    icons: {
      main: '#acaadd',
      dark: '#7f5dff'
    }
  },
  mixins: {
    toolbar: {
      minHeight: 68
    }
  }
}

export const theme = createMuiTheme({
  ...common,
  palette: {
    ...common.palette,
    bar: {
      main: '#5b6baf',
      dark: common.palette.primary.dark
    }
  }
})

export const internalTheme = createMuiTheme({
  ...common,
  palette: {
    ...common.palette,
    bar: {
      main: '#7b9736',
      dark: '#6b8719'
    }
  }
})
