import React, { useState, useMemo, useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Add from '@material-ui/icons/Add'
import update from 'immutability-helper'

import Nav from './Nav'
import Table from './Table'
import Modal from './Modal'
import { Provider } from './context'

import {
  INTERNAL_PAGE_TITLES,
  PAGE_TITLES,
  DATA_TYPES,
  STATUS_NOT_STARTED,
  STATUS_TO_REVIEW,
  STATUS_IN_REVIEW
} from './constants'
import initialData from './data'
import { toReviewExist } from './helpers'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh'
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2, 0)
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  title: {
    fontFamily: "'Lato', sans-serif",
    fontWeight: 600
  },
  subtitle: {
    fontFamily: "'Lato', sans-serif",
    fontWeight: 600,
    color: theme.palette.grey[600]
  },
  paper: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
    boxShadow: '0 2px 14px 0 rgba(0, 0, 0, 0.1)',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2, 1),
      margin: theme.spacing(3, -2, 0)
    },
    [theme.breakpoints.up('md')]: {
      '&:before': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 100,
        background: theme.palette.grey[400],
        zIndex: 0,
        borderRadius: theme.spacing(0.5, 0.5, 0, 0)
      }
    }
  },
  buttons: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1)
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto'
    }
  },
  submitButton: {
    textTransform: 'none',
    fontWeight: 600
  },
  addButton: {
    height: 40,
    padding: theme.spacing(1),
    minWidth: 40,
    '&:not(:only-child)': {
      marginLeft: theme.spacing(1)
    },
    '& .MuiButton-startIcon': {
      margin: 0
    }
  },
  list: {
    padding: theme.spacing(1)
  },
  listItem: {
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.palette.grey[300]
    },
    '&:not(:last-child)': {
      marginBottom: theme.spacing(0.5)
    }
  },
  listItemText: {
    color: theme.palette.grey[600],
    fontWeight: 600
  }
}))

export default () => {
  const classes = useStyles()
  const [data, setData] = useState(initialData)
  const [open, setOpen] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const match = useRouteMatch('/internal')

  const handleAddClick = useCallback(
    ({ currentTarget }) => setAnchorEl(anchorEl ? null : currentTarget),
    [anchorEl]
  )

  const addPopup = Boolean(anchorEl)
  const id = addPopup ? 'transitions-popper' : undefined

  const openItem = useCallback(id => setOpen(id), [])

  const handleClose = useCallback(() => setOpen(null), [])

  const value = useMemo(
    () => ({
      data,
      setData
    }),
    [data, setData]
  )

  const openedItem = useMemo(() => data.find(({ id }) => id === open), [
    data,
    open
  ])

  const onNewItemClick = useCallback(
    type => {
      handleAddClick({})

      setData(
        update(data, {
          $push: [
            {
              id: data.length + 1,
              items: [],
              status: STATUS_NOT_STARTED,
              removable: true,
              ...type
            }
          ]
        })
      )
    },
    [data, handleAddClick]
  )

  const onToReviewClick = useCallback(
    () =>
      setData(
        data.map(({ status, ...others }) => ({
          status: status === STATUS_TO_REVIEW ? STATUS_IN_REVIEW : status,
          ...others
        }))
      ),
    [data]
  )

  return (
    <Provider value={value}>
      <div className={classes.root}>
        <Nav />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div className={classes.header}>
            <div>
              <Typography
                variant='subtitle2'
                component='h2'
                className={classes.subtitle}
              >
                {(match ? INTERNAL_PAGE_TITLES : PAGE_TITLES).subtitle}
              </Typography>
              <Typography variant='h5' component='h1' className={classes.title}>
                {(match ? INTERNAL_PAGE_TITLES : PAGE_TITLES).title}
              </Typography>
            </div>
            <div className={classes.buttons}>
              {!match && (
                <Button
                  color='primary'
                  variant='contained'
                  className={classes.submitButton}
                  size='large'
                  disabled={!toReviewExist(data)}
                  onClick={onToReviewClick}
                >
                  Iesniegt izskatīšanai
                </Button>
              )}
              <Button
                color='primary'
                variant='contained'
                aria-label='Pievienot'
                size='large'
                className={classes.addButton}
                startIcon={<Add />}
                onClick={!match ? handleAddClick : null}
              />
              <Popper id={id} open={addPopup} anchorEl={anchorEl} transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <List
                        component='nav'
                        aria-label='Iespējas'
                        className={classes.list}
                      >
                        {DATA_TYPES.map(type => (
                          <ListItem
                            button
                            key={type.name}
                            onClick={
                              type.description
                                ? () => onNewItemClick(type)
                                : null
                            }
                            className={classes.listItem}
                          >
                            <ListItemText
                              primary={type.name}
                              primaryTypographyProps={{
                                className: classes.listItemText
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </div>
          </div>
          <Paper className={classes.paper}>
            <Table openItem={openItem} />
            <Modal open={!!open} onClose={handleClose} item={openedItem} />
          </Paper>
        </main>
      </div>
    </Provider>
  )
}
