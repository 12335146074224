import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { DatePicker } from '@material-ui/pickers'
import CancelIcon from '@material-ui/icons/Cancel'
import { Field } from 'formik'

import { DATE_FORMAT } from './constants'

export default ({ name, label, className }) => (
  <Field name={name}>
    {({ field: { value }, meta: { error }, form: { setFieldValue } }) => (
      <DatePicker
        className={className}
        format={DATE_FORMAT}
        margin='none'
        inputVariant='filled'
        error={!!error}
        helperText={error}
        label={label}
        required={!!label}
        okLabel='Saglabāt'
        cancelLabel='Atcelt'
        TextFieldComponent={props => (
          <TextField
            {...props}
            InputProps={
              value
                ? {
                    endAdornment: (
                      <InputAdornment
                        position='end'
                        onClick={e => {
                          e.stopPropagation()

                          setFieldValue(name, null)
                        }}
                      >
                        <IconButton
                          aria-label='Notīrīt'
                          color='default'
                          size='small'
                        >
                          <CancelIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                : null
            }
            InputLabelProps={{
              shrink: true
            }}
          />
        )}
        value={value}
        onChange={date => setFieldValue(name, date)}
      />
    )}
  </Field>
)
